body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
    background-color:#fff;
    width:16px;
}
::-webkit-scrollbar-track {
    background-color: #fff
}
::-webkit-scrollbar-thumb {
    background-color:#babac0;
    border-radius:16px;
    border:4px solid #fff
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}
.ant-message .anticon{
  top: -2px !important;
}

.modal-body{
  padding: 0 !important;
}

.invite-admin-modal {
  max-width: 890px !important;
}
.report-modal {
  min-width: 100vw !important;
  min-height: 100vh !important;
  background: transparent !important; 
  margin: 0;
}
.report-modal .modal-content {
  background: transparent !important;
  border: none !important;
}

#react-confirm-alert .sl-warning-modal,
#react-confirm-alert .sl-success-modal,
#react-confirm-alert .sl-confirm-modal {
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

#react-confirm-alert .sl-warning-modal .react-confirm-alert .react-confirm-alert-body,
#react-confirm-alert .sl-success-modal .react-confirm-alert .react-confirm-alert-body,
#react-confirm-alert .sl-confirm-modal .react-confirm-alert .react-confirm-alert-body {
  width: 580px;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #484848;
  text-align: center;
  border-radius: 12px;
  padding: 64px 32px;
}

#react-confirm-alert .sl-success-modal .react-confirm-alert .react-confirm-alert-body {
  background-image: url("data:image/svg+xml,%3Csvg width='52' height='52' viewBox='0 0 52 52' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M26 50C39.2548 50 50 39.2548 50 26C50 12.7452 39.2548 2 26 2C12.7452 2 2 12.7452 2 26C2 39.2548 12.7452 50 26 50ZM26 52C40.3594 52 52 40.3594 52 26C52 11.6406 40.3594 0 26 0C11.6406 0 0 11.6406 0 26C0 40.3594 11.6406 52 26 52Z' fill='%2352CA84'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M35.7071 19.2929C36.0976 19.6834 36.0976 20.3166 35.7071 20.7071L23.7071 32.7071C23.3166 33.0976 22.6834 33.0976 22.2929 32.7071L16.2929 26.7071C15.9024 26.3166 15.9024 25.6834 16.2929 25.2929C16.6834 24.9024 17.3166 24.9024 17.7071 25.2929L23 30.5858L34.2929 19.2929C34.6834 18.9024 35.3166 18.9024 35.7071 19.2929Z' fill='%2352CA84'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: top 64px center;
  padding-top: 140px;
}

#react-confirm-alert .sl-warning-modal .react-confirm-alert .react-confirm-alert-body {
  background-image: url("data:image/svg+xml,%3Csvg width='52' height='52' viewBox='0 0 52 52' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M26 50C39.2548 50 50 39.2548 50 26C50 12.7452 39.2548 2 26 2C12.7452 2 2 12.7452 2 26C2 39.2548 12.7452 50 26 50ZM26 52C40.3594 52 52 40.3594 52 26C52 11.6406 40.3594 0 26 0C11.6406 0 0 11.6406 0 26C0 40.3594 11.6406 52 26 52Z' fill='%23FFA800'/%3E%3Crect x='25' y='14' width='2' height='20' rx='1' fill='%23FFA800'/%3E%3Ccircle cx='26' cy='37' r='1' fill='%23FFA800'/%3E%3C/svg%3E");;
  background-repeat: no-repeat;
  background-position: top 64px center;
  padding-top: 140px;
}

#react-confirm-alert .sl-warning-modal .react-confirm-alert .react-confirm-alert-body .react-confirm-alert-button-group,
#react-confirm-alert .sl-success-modal .react-confirm-alert .react-confirm-alert-body .react-confirm-alert-button-group,
#react-confirm-alert .sl-confirm-modal .react-confirm-alert .react-confirm-alert-body .react-confirm-alert-button-group {
  justify-content: center;
  margin-top: 32px;
}

#react-confirm-alert .sl-warning-modal .react-confirm-alert .react-confirm-alert-body .react-confirm-alert-button-group button,
#react-confirm-alert .sl-success-modal .react-confirm-alert .react-confirm-alert-body .react-confirm-alert-button-group button,
#react-confirm-alert .sl-confirm-modal .react-confirm-alert .react-confirm-alert-body .react-confirm-alert-button-group button {
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #2B2B2B;
  padding: 16px;
  width: 200px;
}

#react-confirm-alert .sl-warning-modal .react-confirm-alert .react-confirm-alert-body .react-confirm-alert-button-group button,
#react-confirm-alert .sl-success-modal .react-confirm-alert .react-confirm-alert-body .react-confirm-alert-button-group button {
  margin-right: 0;
}

#react-confirm-alert .sl-warning-modal .react-confirm-alert .react-confirm-alert-body .react-confirm-alert-button-group button:nth-child(even) {
  margin-left: 24px;
}

#react-confirm-alert .sl-confirm-modal .react-confirm-alert .react-confirm-alert-body .react-confirm-alert-button-group .delete-btn {
  background: #F45B5B;
  color: #FFFFFF;
  border: 1px solid #F45B5B;
}

#react-confirm-alert .sl-warning-modal .react-confirm-alert .react-confirm-alert-body .react-confirm-alert-button-group .confirm-btn,
#react-confirm-alert .sl-confirm-modal .react-confirm-alert .react-confirm-alert-body .react-confirm-alert-button-group .confirm-btn {
  background: #457EF5;
  color: #FFFFFF;
  border: 1px solid #457EF5;
}
